.Root {
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

.Card {
  background-color: #ebeff6;
  text-align: center;
  padding: 24px 48px;
  border-radius: 12px;
}

.Card .Title {
  font-size: 24px;
  font-weight: 500;
}

.Card .Countdown {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.Card .Counter .Number {
  background-color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 8px;
}

.Card .Counter .Unit {
  font-weight: 500;
}
