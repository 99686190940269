@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.sr-only {
  display:none;
}

.Countdown_Root__3cVHw {
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

.Countdown_Card__2i9aE {
  background-color: #ebeff6;
  text-align: center;
  padding: 24px 48px;
  border-radius: 12px;
}

.Countdown_Card__2i9aE .Countdown_Title__3jWKl {
  font-size: 24px;
  font-weight: 500;
}

.Countdown_Card__2i9aE .Countdown_Countdown__MUoR2 {
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  gap: 12px;
}

.Countdown_Card__2i9aE .Countdown_Counter__3LGLL .Countdown_Number__2U1Q8 {
  background-color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 8px;
}

.Countdown_Card__2i9aE .Countdown_Counter__3LGLL .Countdown_Unit__2TvWL {
  font-weight: 500;
}

